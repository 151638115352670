/* 
*   Styles for sidebar container.
*/
.sidebar {
    --opened-sizebar-width: 200px;
    --icon-button-width: 48px;
    --sidebar-container-padding: 15px;
    --closed-sizebar-width: calc(var(--icon-button-width) + 2 * var(--sidebar-container-padding));

    padding: var(--sidebar-container-padding);

    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 10px;

    width: var(--closed-sizebar-width);
    transition: all 0.3s ease-in-out;

    border-right: 2px solid #a8a8a859;
}

.sidebar_opened {
    width: var(--opened-sizebar-width);
    transition: all 0.3s ease-in-out;
}

.sidebar__bottom-button {
    margin-top: auto;
}

.sidebar__opening-button {
    transition: all 0.4s ease-in-out;
}

.sidebar__opening-button_opened {
    transform: rotate(0.5turn);
}

.to-end {
    align-self: flex-end;
}

/*
*   Styles for button element in sidebar. 
*   It contains icon, which remains always, and label, which appears on opening.
*/

.sidebar__button {
    background-color: #ffffff00;
    border-radius: 30px;

    display: flex;
    align-items: center;
    gap: 20px;

    padding: 10px 12px 10px 10px;

    cursor: pointer;
}

.sidebar__button:hover {
    background-color: #bebebe28;
}

.sidebar__button__icon {
    color: #757575;
    /* width: var(--icon-button-width);
    height: fit-content; */
}

.sidebar__button__label {
    display: none;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
}

.sidebar__button__label_opened {
    display: block;
    animation: fadeIn 1s;
    text-wrap: wrap;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sidebar hr {
    border-color: #b8b8b8;
    background-color: #b8b8b8;
    color:#b8b8b8;
}


/*
*   Extra styles.
*/

ul {
    list-style-type: none;
    padding: 0;
    margin: 5px 0;
}

li {
    padding: 0;
}

.fullwidth {
    width: 100%;
}